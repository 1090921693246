// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  pinksheet: icon('ic_file'),
  diagnostics: icon('ic_external'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Warehouse',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.warehouse, icon: ICONS.dashboard },
      { title: 'Shipments', path: PATH_DASHBOARD.shipments, icon: ICONS.ecommerce },
      { title: 'Pink Sheets', path: PATH_DASHBOARD.pinksheets, icon: ICONS.pinksheet },
      { title: 'Invoices', path: PATH_DASHBOARD.invoices, icon: ICONS.ecommerce },
      // { title: 'PowerBI', path: PATH_DASHBOARD.powerbi, icon: ICONS.analytics },
      { title: 'Diagnostics', path: PATH_DASHBOARD.diagnostics, icon: ICONS.diagnostics },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.user.four },
  //         { title: 'Five', path: PATH_DASHBOARD.user.five },
  //         { title: 'Six', path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;

import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
export const Shipments = Loadable(lazy(() => import('../pages/Shipments')));
export const PowerBi = Loadable(lazy(() => import('../pages/PowerBi')));
export const PinkSheets = Loadable(lazy(() => import('../pages/PinkSheets')));
export const Diagnostics = Loadable(lazy(() => import('../pages/Diagnostics')));
export const ShipmentPinkSheets = Loadable(lazy(() => import('../pages/ShipmentPinkSheets')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

import axios from "axios";

const pagedShipments = async (page: 0, size: 20) => {
    const url = `/shipments`;

    try {
        return await axios.get(url, { params: {page, size}});
    } catch (err) {
        console.log(err);
        return null;
    }
}

const pagedFailedShipments = async (page: number, size: number) => {
    const url = `/shipments/failed`;

    try {
        return await axios.get(url, { params: {page, size}});
    } catch (err) {
        console.log(err);
        return null;
    }
}

const deprecateShipment = async (no: string) => {
    const url = `/shipments/deprecate/${no}`;

    try {
        return await axios.delete(url, { params: {no}});
    } catch (err) {
        console.log(err);
        return null;
    }
}

const getShipment = async (no: string) => {
    const url = `/shipments/${no}`;

    try {
        return await axios.get(url, { params: {no}});
    } catch (err) {
        console.log(err);
        return null;
    }
}

const ShipmentsService = {
    pagedShipments,
    pagedFailedShipments,
    deprecateShipment,
    getShipment
}

export default ShipmentsService;
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export const HOST_API = process.env.REACT_APP_HOST_API || '';

export const AD_API = {
  clientId: process.env.REACT_APP_AD_CLIENT_ID || '',
  authority: process.env.REACT_APP_AD_TENANT_ID,
  scopes: process.env.REACT_APP_AD_PROTECTED_SCOPES? process.env.REACT_APP_AD_PROTECTED_SCOPES.split(",") : [''],
  redirectUri: process.env.REACT_APP_AD_REDIRECT_URI || 'http://localhost:3000/blank.html',
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.warehouse;

export const REACT_SIGNALR_NEGOTIATE_URL = process.env.REACT_APP_SIGNALR_NEGOTIATE_URL || "http://localhost:7071/api/negotiate";

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_LARGE: 320,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

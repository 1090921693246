import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import ShipmentsService from 'src/services/ShipmentsService';
import { IShipmentState } from '../../@types/shipment';

// ----------------------------------------------------------------------

const initialState: IShipmentState = {
    isLoading: false,
    error: null,
    data: [],
    page: 0,
    per_page: 20,
    total: 0,
    total_pages: 0
  };
  
  const slice = createSlice({
    name: 'shipment',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      // GET SHIPMENTS
      getShipmentsSuccess(state, action) {
        state.isLoading = false;
        state.data = action.payload.data.map((item: any) => ({header: {
          ...item.shipmentHeader, 
          createdAt: new Date(item.shipmentHeader.createdAt),
          documentDate: new Date(item.shipmentHeader.documentDate),
        }, details: item.shipmentDetails}));
        state.page = action.payload.page;
        state.per_page = action.payload.perPage;
      },
    },
  });

// Reducer
export default slice.reducer;

// Actions
export const {
    getShipmentsSuccess,
  } = slice.actions;

// ----------------------------------------------------------------------

export function getShipments(page: 0, perPage: 20) {
    return async (dispatch: Dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await ShipmentsService.pagedShipments(page, perPage);
        dispatch(slice.actions.getShipmentsSuccess({page, perPage, data: response?.data.data}));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
// ----------------------------------------------------------------------
 
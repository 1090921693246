// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  warehouse: path(ROOTS_DASHBOARD, '/warehouse'),
  shipments: path(ROOTS_DASHBOARD, '/shipments'),
  pinksheets: path(ROOTS_DASHBOARD, '/pinksheets'),
  // powerbi: path(ROOTS_DASHBOARD, '/powerbi'),
  diagnostics: path(ROOTS_DASHBOARD, '/diagnostics'),
  invoices: path(ROOTS_DASHBOARD, '/invoices'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
  },
};
